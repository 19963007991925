<template>
  <div
    style="padding-top:50px;padding-bottom:50px;"
  >
    <div style="justify-content:left; background-color:#f8f8f8;">
      <b-row class="auth-inner m-0">
        <!-- Register-->
        <b-col
          lg="12"
          class="d-flex align-items-center-disabble auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="12"
            md="12"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <div
              style="/* text-align:center; margin-top:-50px; */"
            >
              <b-img
                style="width:150px;"
                :src="require(`@/assets/images/partner/${partner}/logo/logo.png`)"
              />
              <br><br>
            </div>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              <br>
              <app-collapse
                accordion
                type="margin"
              >

                <app-collapse-item
                  v-for="f in faq"
                  :key="f.id"
                  :title="f.title"
                >
                  {{ f.description }}
                </app-collapse-item>
              </app-collapse>
            </b-card-title>
          </b-col>
          <div
            style="position: absolute; top: 20px; right: 10px; font-size:12px; color:#5e587 !important;"
          >
            <b-button
              :variant="`outline-${partnerData.colorScheme}`"
              size="sm"
              @click="$router.go(-1)"
              pill
            >
              Schließen
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { localize } from 'vee-validate'
import {
  BRow, BCol, BImg, BButton,
} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { ref } from '@vue/composition-api'
import store from '@/store/index'
import homeStoreModule from './homeStoreModule'

localize('de')
export default {
  components: {
    // VuexyLogo,
    BRow,
    BImg,
    BCol,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/register-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    const APP_HOME_STORE_MODULE_NAME = 'app-home'

    // Register module
    if (!store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.registerModule(APP_HOME_STORE_MODULE_NAME, homeStoreModule)

    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`

    //  document.title = partnerData.value.htmlPageTitle
    //  document.querySelector("link[rel*='icon']").href = `favicon_${partnerData.value.key}.ico`
    const appIdElem = document.getElementById('app')
    let backgroundImage = ''
    if (typeof (window.$cookies.get('partner')) !== 'undefined') {
      backgroundImage = require(`@/assets/images/partner/${partnerData.value.key}/backgorund/auth.jpg`)
      appIdElem.style.backgroundImage = `url('${backgroundImage}')`
    }

    const faq = ref([])

    store
      .dispatch(`${APP_HOME_STORE_MODULE_NAME}/getFaq`)
      .then(response => {
        faq.value = response.data
      })
      .catch(error => {
        console.log(error)
      })

    return {
      partner,
      partnerData,
      getHrefStyle,
      faq,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
